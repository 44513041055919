import React from 'react'

import IconHTMLExample from './IconHTMLExample'

const AccordionCollapsibleStepsExample = () => (
  <div data-toggle-context>
    <div className="accordion">
      <div className="accordion-header">
        <h2 className="h4">
          <button
            aria-controls="accordion-collapse-11"
            aria-expanded="false"
            className="accordion-button"
            data-toggle="collapsible-step"
            id="accordion-button-11"
            type="button"
          >
            <span className="accordion-button-content">
              <span className="accordion-bullet accordion-bullet-active">
                1
              </span>
              <span className="accordion-button-label mt-xxs">
                Mikä on työnhakuprofiili?
              </span>
              <span className="accordion-button-icon">
                <IconHTMLExample
                  className="accordion-button-icon-expand"
                  name="ChevronDownIcon"
                  size="lg"
                />
                <IconHTMLExample
                  className="accordion-button-icon-collapse"
                  name="ChevronUpIcon"
                  size="lg"
                />
              </span>
            </span>
          </button>
        </h2>
      </div>
      <div
        aria-labelledby="accordion-button-11"
        className="accordion-collapse collapse"
        id="accordion-collapse-11"
      >
        <div className="accordion-content">
          <p>
            Työnhakuprofiilin avulla löydät osaamistasi vastaavia työpaikkoja.
            Sen avulla kerrot itsestäsi työnantajalle kuka olet ja mitä osaat.{' '}
            <strong>Työnhakuprofiilisi on anonyymi</strong>, ellet itse päätä
            toisin.
          </p>
          <p className="mb-0">
            <button
              className="btn btn-plain-primary"
              data-toggle="next-step"
              type="button"
            >
              <span className="btn-content">
                <span className="btn-label">Avaa seuraava</span>
                <IconHTMLExample
                  className="btn-icon-right"
                  name="ArrowDownIcon"
                  size="lg"
                />
              </span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div className="accordion">
      <div className="accordion-header">
        <h2 className="h4">
          <button
            aria-controls="accordion-collapse-12"
            aria-expanded="false"
            className="accordion-button"
            data-toggle="collapsible-step"
            id="accordion-button-12"
            type="button"
          >
            <span className="accordion-button-content">
              <span className="accordion-bullet">2</span>
              <span className="accordion-button-label mt-xxs">
                Miksi työnhakuprofiili kannattaa täyttää?
              </span>
              <span className="accordion-button-icon">
                <IconHTMLExample
                  className="accordion-button-icon-expand"
                  name="ChevronDownIcon"
                  size="lg"
                />
                <IconHTMLExample
                  className="accordion-button-icon-collapse"
                  name="ChevronUpIcon"
                  size="lg"
                />
              </span>
            </span>
          </button>
        </h2>
      </div>
      <div
        aria-labelledby="accordion-button-12"
        className="accordion-collapse collapse"
        id="accordion-collapse-12"
      >
        <div className="accordion-content">
          <p>
            Työnhakuprofiilin avulla löydät osaamistasi vastaavia työpaikkoja.
            Sen avulla kerrot itsestäsi työnantajalle kuka olet ja mitä osaat.{' '}
            <strong>Työnhakuprofiilisi on anonyymi</strong>, ellet itse päätä
            toisin.
          </p>
          <p className="mb-0">
            <button
              className="btn btn-plain-primary"
              data-toggle="next-step"
              type="button"
            >
              <span className="btn-content">
                <span className="btn-label">Avaa seuraava</span>
                <IconHTMLExample
                  className="btn-icon-right"
                  name="ArrowDownIcon"
                  size="lg"
                />
              </span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div className="accordion">
      <div className="accordion-header">
        <h2 className="h4">
          <button
            aria-controls="accordion-collapse-13"
            aria-expanded="false"
            className="accordion-button"
            data-toggle="collapsible-step"
            id="accordion-button-13"
            type="button"
          >
            <span className="accordion-button-content">
              <span className="accordion-bullet">3</span>
              <span className="accordion-button-label mt-xxs">
                Miten työnhakuprofiili toimii? Entä jos otsikko on aivan liian
                pitkä?
              </span>
              <span className="accordion-button-icon">
                <IconHTMLExample
                  className="accordion-button-icon-expand"
                  name="ChevronDownIcon"
                  size="lg"
                />
                <IconHTMLExample
                  className="accordion-button-icon-collapse"
                  name="ChevronUpIcon"
                  size="lg"
                />
              </span>
            </span>
          </button>
        </h2>
      </div>
      <div
        aria-labelledby="accordion-button-13"
        className="accordion-collapse collapse"
        id="accordion-collapse-13"
      >
        <div className="accordion-content">
          <p className="mb-0">
            Työnhakuprofiilin avulla löydät osaamistasi vastaavia työpaikkoja.
            Sen avulla kerrot itsestäsi työnantajalle kuka olet ja mitä osaat.{' '}
            <strong>Työnhakuprofiilisi on anonyymi</strong>, ellet itse päätä
            toisin.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export { AccordionCollapsibleStepsExample }
