import React, { useEffect } from 'react'

import { Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import lorem from '../../lorem'
import { AccordionCollapsibleStepsExample } from '../../examples/html/AccordionCollapsibleStepsExample'

const Page = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
  }, [])

  return (
    <Content
      heading="Accordion"
      subHeading="Verkkopalvelu"
    >
      <Playground format="html">
        <div data-toggle-context>
          {[
            'Lorem ipsum dolor sit amet',
            'Consectetuer adipiscing elit',
            'Sed posuere interdum sem'
          ].map((title, i) => (
            <div
              className="accordion"
              key={i}
            >
              <div className="accordion-header">
                <h3 className={`h4 ${i !== 0 ? 'mb-0' : ''}`}>
                  <button
                    aria-controls={`accordion-collapse-${i}`}
                    aria-expanded="false"
                    className="accordion-button"
                    data-toggle="collapse"
                    id={`accordion-button-${i}`}
                    type="button"
                  >
                    <span className="accordion-button-content">
                      <span className="accordion-button-label">{title}</span>
                      <span className="accordion-button-icon">
                        <IconHTMLExample
                          className="accordion-button-icon-expand"
                          name="ChevronDownIcon"
                          size="lg"
                        />
                        <IconHTMLExample
                          className="accordion-button-icon-collapse"
                          name="ChevronUpIcon"
                          size="lg"
                        />
                      </span>
                    </span>
                  </button>
                </h3>
                {i === 0 && <p className="mb-0">{lorem({ sentences: 1 })}</p>}
              </div>
              <div
                aria-labelledby={`accordion-button-${i}`}
                className="accordion-collapse collapse"
                id={`accordion-collapse-${i}`}
              >
                <div className="accordion-content">
                  <p className="mb-0">{lorem({ sentences: 10 })}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Playground>
      <Paragraph>
        CollapsibleSteps-versio käytössä Asioinnin käyttöliittymissä.
      </Paragraph>
      <Playground
        format="html"
        example={AccordionCollapsibleStepsExample}
      />
    </Content>
  )
}

export default Page
